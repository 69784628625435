<template lang="pug">
.container-fluid.px-3
  om-modal(name="apiKey-modify" modalClasses="api-key-modal" :width="480" color="light")
    template(slot="modal-header")
      .dialog-content.font-size-0--875.font-weight-bold(
        v-html="$t(modalType === 'generate' ? 'apiKeySettings.buttonGenerateNew' : 'apiKeySettings.buttonDelete')"
      )
      .brand-modal-action-icon(v-if="!inProgress")
        span.cursor-pointer(@click="$modal.hide('apiKey-modify')")
          close-icon(:width="14" :height="14" :color="'#C2C2C2'")
    template(slot="modal-body")
      .dialog-text.font-size-0--875.mt-3(
        v-html="$t(modalType === 'generate' ? 'apiKeySettings.modalGenerate' : 'apiKeySettings.modalDelete')"
      )
    template(slot="modal-footer")
      .vue-dialog-buttons.d-flex.justify-content-end.products-missing-btn-box
        om-button.mr-3(ghost @click="$modal.hide('apiKey-modify')") {{ $t('cancelDelete') }}
        template(v-if="modalType === 'generate'")
          om-button(primary :class="{ loading: inProgress }" @click="generateKey") {{ $t('apiKeySettings.modalButtonGenerate') }}
        template(v-else)
          om-button(primary :class="{ loading: inProgress }" @click="deleteKey") {{ $t('delete') }}
  .row
    .col.mb-4
      om-heading.mt-1(h1) {{ $t('apiKeySettings.label') }}
    .col-12.mt-2.mb-4.font-size-0--875 {{ $t('apiKeySettings.description') }}
      span.cursor-pointer.btn-link.font-weight-bold(@click="openDocumentationPage") {{ documentationUrl }}
    template(v-if="apiKey")
      .col-5.mt-3
        label.mb-3.font-size-0--875(for="p_fname") {{ $t('apiKeySettings.labelCurrent') }}
        .mb-4.position-relative
          input.form-control.form-control-md(type="text" disabled v-model="apiKey")
          popper(trigger="click" :options="{ placement: 'top' }" @created="timeoutPopper")
            .popper.brand-settings-popover.brand-settings-popover-alerts
              .brand-settings-popover-alerts-inner
                span {{ $t(clipBoardCopySuccess ? 'notifications.insertCodeCopySuccess' : 'notifications.insertCodeCopyError') }}
            button.brand-copy-api(
              slot="reference"
              v-clipboard:copy="apiKey"
              v-clipboard:success="() => clipBoardCopySuccess = true"
              v-clipboard:error="() => clipBoardCopySuccess = false"
            )
              i.far.fa-copy
        .d-flex
          om-button(secondary @click="openModal('generate')") {{ $t('apiKeySettings.buttonGenerateNew') }}
          om-button.mx-3(ghost @click="openModal('delete')") {{ $t('apiKeySettings.buttonDelete') }}
    template(v-else)
      .col-6
        om-button(primary :class="{ loading: inProgress }" @click="generateKey") {{ $t('apiKeySettings.buttonGenerate') }}
</template>

<script>
  import runtimeConfig from '@/config/runtime';
  import GENERATE_API_KEY from '@/graphql/GenerateApiKey.gql';
  import DELETE_API_KEY from '@/graphql/DeleteApiKey.gql';
  // eslint-disable-next-line
  import Get_API_KEY from '@/graphql/GetApiKey.gql';

  export default {
    data() {
      return {
        apiKey: '',
        inProgress: false,
        documentationUrl: runtimeConfig.VUE_APP_API_DOCUMENTATION_URL,
        modalType: false,
        clipBoardCopySuccess: null,
      };
    },

    mounted() {
      this.$apollo.query({ query: Get_API_KEY }).then(({ data }) => {
        this.apiKey = data.apiKey;
      });
    },

    methods: {
      openDocumentationPage() {
        window.open(this.documentationUrl, '_blank');
      },
      timeoutPopper(instance) {
        setTimeout(() => instance.doClose(), 2000);
      },
      openModal(type) {
        this.modalType = type;
        this.$modal.show('apiKey-modify');
      },
      closeModal() {
        this.$modal.hide('apiKey-modify');
        this.modalType = false;
      },
      async deleteKey() {
        this.inProgress = true;
        try {
          await this.$apollo.mutate({ mutation: DELETE_API_KEY });
          this.apiKey = '';
          this.closeModal();
          this.$notify({
            type: 'success',
            title: this.$t('apiKeySettings.notifications.keyDeleted'),
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('apiKeySettings.notifications.keyDeleteFail'),
          });
        }
        this.inProgress = false;
      },
      async generateKey() {
        this.inProgress = true;
        try {
          const { data } = await this.$apollo.mutate({ mutation: GENERATE_API_KEY });
          this.apiKey = data.generateApiKey;
          this.closeModal();
          this.$notify({
            type: 'success',
            title: this.$t('apiKeySettings.notifications.keyGenerated'),
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('apiKeySettings.notifications.keyGenerateFail'),
          });
        }
        this.inProgress = false;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .form-control:disabled,
  .form-control[readonly]
    background: #F6F8F9
    border-radius: 5px
    max-height: 3rem
    border: 1px solid #F6F8F9
    color: #505763
    font-size: 14px
    font-weight: bold
    padding-left: 1rem
    padding-right: 1rem
  .brand-btn-secondary
    background: transparent
    border: 1px solid #E3E5E8
    box-shadow: none
    color: #505763
    padding-left: 1rem
    padding-right: 1rem
    font-weight: bold
    &:hover
      background: #F1F2F4
      opacity: 1
    &:active
      background: #E3E5E8
  .brand-btn-link
    border-radius: 3px
    padding-left: 1rem
    padding-right: 1rem
    color: #505763
    font-size: 14px
    font-weight: bold
    transition: 0.5s
    &:hover
      background: #F1F2F4
      opacity: 1
    &:active
      background: #E3E5E8
  .brand-copy-api
    position: absolute
    top: 0
    right: 0
    font-size: 1.25rem
    padding: .25rem 1rem
    height: 2.5rem
    background: transparent
    border: 0
    transition: 0.5s
    border-radius: 5px
    &:hover
      background: #F1F2F4
    &:active
      background: #E3E5E8
    &:focus
      outline: 5px auto #E3E5E8
</style>
<style lang="sass">
  .api-key-modal
    .brand-modal .brand-modal-header
      padding-top: 1.3125rem
      padding-left: 2.5rem
      padding-bottom: 1.3125rem
      padding-right: 2.5rem
      border-bottom: 1px solid #E9EFF4
    .brand-modal-body,
    .brand-modal-footer
      padding-left: 2.5rem
      padding-right: 2.5rem
    .brand-modal-footer
      padding-top: 2.1875rem
      padding-bottom: 1.25rem
</style>
